<template>
  <section class="books-page">
    <h1>Books</h1>
  </section>
</template>

<script>
export default {
  name: 'Books'
}
</script>

<style lang="scss">
.books-page {
}
</style>
